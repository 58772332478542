<template>
  <v-row>
    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        v-model="params.isActive"
        :items="[
          { text: 'Active', value: true },
          { text: 'Inactive', value: false },
        ]"
        label="Status"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
    <template v-if="tab === 0">
      <v-col cols="12" md="6" lg="2">
        <v-select
          clearable
          v-model="departmentId"
          :items="departments"
          label="Department"
          item-text="name"
          item-value="id"
          outlined
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-select
          clearable
          v-model="designationId"
          :items="designations"
          label="Designation"
          item-text="name"
          item-value="id"
          outlined
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <v-select
          clearable
          v-model="roleId"
          :items="roles"
          label="Role"
          item-text="name"
          item-value="id"
          outlined
          hide-details
          dense
        ></v-select>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['params', 'options', 'tab'],
  data() {
    return {
      departmentId: null,
      roleId: null,
      designationId: null,
    }
  },
  watch: {
    tab() {
      this.departmentId = null
      this.roleId = null
      this.designationId = null
    },
    departmentId() {
      this.roleId = null
      this.designationId = null
      this.params.departments = this.departmentId ? [this.departmentId] : null
      this.$emit('onSearch')
    },
    roleId() {
      this.params.roles = this.roleId ? [this.roleId] : null
      this.$emit('onSearch')
    },
    designationId() {
      this.params.designations = this.designationId ? [this.designationId] : null
      this.$emit('onSearch')
    },
  },
  computed: {
    ...mapState('category', ['departments']),

    roles() {
      if (!this.departmentId) return []
      return this.departments.find(d => d.id === this.departmentId).roles
    },

    designations() {
      if (!this.departmentId) return []
      return this.departments.find(d => d.id === this.departmentId).designations
    },
  },
}
</script>
<style lang=""></style>
