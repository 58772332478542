<template>
  <v-row>
    <v-col v-if="!hideTotal" cols="12" lg="2" sm="4" md="3" class="py-0 d-flex align-center">
      <span style="font-size: 14px; font-weight: 500"
        >Total: {{ total }} <span v-if="all">out of {{ all }}</span></span
      >
    </v-col>

    <v-col cols="12" lg="8" sm="4" md="6" class="py-0">
      <v-pagination
        v-model="params.page"
        :length="length"
        total-visible="10"
        @input="$emit('onPageChange')"
      ></v-pagination>
    </v-col>
    <v-col cols="12" lg="2" sm="4" md="3" class="py-0">
      <v-select
        class="ml-sm-auto mt-2"
        dense
        :style="{
          width: '120px',
        }"
        item-text="text"
        :items="pages"
        item-value="value"
        v-model="params.perPage"
        hide-details
        @change="$emit('onPerPageChange')"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ['length', 'params', 'total', 'hideTotal', 'all'],
  computed: {
    pages() {
      return [
        {
          value: 10,
          text: `10 / page`,
        },
        {
          value: 15,
          text: `15 / page`,
        },
        {
          value: 20,
          text: `20 / page`,
        },
        {
          value: 50,
          text: `50 / page`,
        },
        {
          value: 100,
          text: `100 / page`,
        },
      ]
    },
  },
  methods: {},
}
</script>
