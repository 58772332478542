<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h1 class="font-weight-medium">User Management</h1>
        <v-btn-toggle mandatory v-model="tab" class="ml-auto" tile color="deep-purple accent-3">
          <v-btn :value="0"> Dashboard </v-btn>
          <v-btn :value="1"> Citizen </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <Search :tab="tab" :params="params" :options="options" @onSearch="getData(1)" @onReset="reset" />
      </v-col>
      <v-col cols="12">
        <DataTable
          :height="$vuetify.breakpoint.md ? undefined : 'calc(100vh - 320px)'"
          search
          :form="form"
          :table-data="tableData"
          :pagination="pagination"
          :params="params"
          :tab="tab"
          @on-search="getData(1)"
          @on-edit="showDialogForm('edit', $event)"
          @on-create="showDialogForm('create')"
          @on-export="exportData"
          @deleted="getData()"
          @updated="getData()"
          @refreshed="getData()"
          @sorted="handleSort"
        />
      </v-col>
      <v-col cols="12">
        <Pagination
          :length="pagination.lastPage"
          :total="pagination.total"
          :params="params"
          @onPageChange="getData"
          @onPerPageChange="getData(1)"
        />
      </v-col>
    </v-row>
    <DialogForm
      :options="options"
      :show-dialog.sync="showDialog"
      :editing="editing"
      :form="form"
      @created="
        getData(1)
        getOption()
      "
      @updated="
        getData()
        getOption()
      "
    />
  </v-container>
</template>

<script>
import DataTable from './components/DataTable.vue'
import Search from './components/Search.vue'
import DialogForm from './components/DialogForm.vue'
import Pagination from '@/components/Pagination.vue'
import { index, exportExcel } from '@/api/user'
import { getWards } from '@/api/category'
import indexMixin from '@/mixins/crud/index'

export default {
  components: {
    DataTable,
    Search,
    DialogForm,
    Pagination,
  },

  mixins: [
    indexMixin(
      index,
      { wards: { func: getWards, params: { select: 'id*name*userId', sortBy: 'id', with: 'user:id*email' } } },
      exportExcel,
    ),
  ],
  watch: {
    tab() {
      this.resetQueryParams()
      this.params.citizen = this.tab === 1
      this.getData(1)
    },
  },
  data() {
    return {
      model: 'user',
      showDialog: false,
      editing: false,
      defaultParams: {
        search: '',
        page: 1,
        perPage: 20,
        with: 'department:id*name;designation:id*name;wards:id*name',
        sortBy: 'id:desc',
        roles: [],
        citizen: false,
      },
      form: {
        id: undefined,
        name: null,
        email: null,
        mobileNumber: null,
        departmentId: null,
        designationId: null,
        roleId: null,
        password: null,
        confirmPassword: null,
        isActive: true,
        wardIds: [],
        wards: [],
      },
      tab: 0,
    }
  },
}
</script>
