const indexMixin = (index, options, exportData) => ({
  data() {
    return {
      model: '',
      showDialog: false,
      editing: false,
      defaultParams: {},
      params: {},
      pagination: {
        last_page: 1,
        total: 0,
      },
      tableData: [],
      options: {},
      defaultForm: {},
      form: {
        id: undefined,
      },
    }
  },
  methods: {
    async getData(page = null) {
      try {
        this.$loader(true)
        if (page) this.params.page = page
        const { data, meta } = await index(this.params)
        this.tableData = []
        this.$nextTick(() => {
          this.tableData = data
          this.pagination = meta
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
    async getOption() {
      if (!options) return
      try {
        const opts = {}
        let results = await Promise.all(Object.values(options).map(option => option.func(option.params)))
        results = results.map(item => item.data)
        Object.keys(options).forEach((item, key) => {
          opts[item] = results[key]
        })
        this.options = { ...opts }
      } catch (error) {
        console.log(error)
      }
    },
    showDialogForm(mode, data = null) {
      if (mode === 'edit') {
        this.editing = true

        Object.keys(this.form).forEach(field => {
          this.form[field] = typeof data[field] === 'object' ? JSON.parse(JSON.stringify(data[field])) : data[field]
        })
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm))
        this.form.id = undefined
        this.editing = false
      }
      this.showDialog = true
    },
    reset() {
      this.resetQueryParams()
      this.getData()
    },
    resetQueryParams() {
      this.params = { ...this.defaultParams }
    },
    handleSort({ sortBy, sortDesc }) {
      if (sortBy) {
        this.params.sortBy = `${sortBy}${sortDesc ? ':desc' : ''}`
      } else this.params.sortBy = this.defaultParams.sortBy || 'id:desc'
      this.getData(1)
    },
    async exportData(type = 'csv') {
      this.$loader(true)
      try {
        const { page, perPage, ...params } = this.params
        const data = await exportData(params, type)
        const url = URL.createObjectURL(
          new Blob([data], {
            type: type === 'csv' ? 'text/csv' : 'application/pdf',
          }),
        )

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.model || 'export'}.${type}`)
        document.body.appendChild(link)
        link.click()
      } finally {
        this.$loader(false)
      }
    },
  },

  created() {
    this.defaultForm = JSON.parse(JSON.stringify(this.form))

    if (options) this.getOption()
    this.resetQueryParams()
  },
})

export default indexMixin
