import request from '@/services/request'
export function index(params) {
  return request({
    url: '/users',
    method: 'get',
    params,
  })
}
export function update(id, data) {
  return request({
    url: `/users/${id}`,
    method: 'put',
    data,
  })
}
export function store(data) {
  return request({
    url: '/users',
    method: 'post',
    data,
  })
}
export function destroy(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete',
  })
}

export function active(id, value) {
  return request({
    url: `/users/${id}/active`,
    method: 'put',
    data: { isActive: value },
  })
}

export function exportExcel(params) {
  return request({
    url: `/users/export`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}
