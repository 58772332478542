<template>
  <v-dialog v-model="showDialog" persistent max-width="650px">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  dense
                  :rules="[v => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field v-model="form.email" label="Email" dense :rules="emailRules"></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field
                  prefix="+91"
                  v-model="phoneNumber"
                  label="Mobile Number"
                  dense
                  :rules="mobileNumberRules"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-select
                  v-model="form.departmentId"
                  label="Department"
                  dense
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'Department is required']"
                ></v-select>
              </v-col>
              <v-col md="6">
                <v-select
                  v-model="form.designationId"
                  label="Designation"
                  dense
                  :items="designations"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'Designation is required']"
                ></v-select>
              </v-col>
              <v-col md="6">
                <v-select
                  v-model="form.roleId"
                  label="Role"
                  dense
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  :rules="[v => !!v || 'Role is required']"
                ></v-select>
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="form.password"
                  dense
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline"
                  :rules="passwordRules"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="form.confirmPassword"
                  dense
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  label="Password Confirmation"
                  placeholder="············"
                  :append-icon="isConfirmPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline"
                  :rules="passwordConfirmationRules"
                  @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                ></v-text-field>
              </v-col>
              <v-col v-if="form.roleId === 2" md="6">
                <v-autocomplete
                  v-model="form.wardIds"
                  clearable
                  label="Ward"
                  multiple
                  dense
                  :items="options.wards"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:item="data">
                    <v-list-item-content two-line>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.user?.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6" v-if="editing">
                <v-switch v-model="form.isActive" label="Active" dense></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-btn v-if="!editing" color="primary" text @click="createData"> Create </v-btn>
        <v-btn v-else color="primary" text @click="updateData"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiTrashCanOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { store, update } from '@/api/user'
import dialogMixin from '@/mixins/crud/dialog'
import { mapState } from 'vuex'

export default {
  mixins: [dialogMixin(store, update)],
  data() {
    return {
      mdiTrashCanOutline,
      mdiEyeOutline,
      mdiEyeOffOutline,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      mobileNumberRules: [v => !!v || 'Mobile Number is required'],
      designations: [],
      roles: [],
    }
  },
  watch: {
    'form.departmentId'() {
      if (this.form.departmentId) {
        this.roles = this.departments.find(d => d.id === this.form.departmentId).roles
        this.designations = this.departments.find(d => d.id === this.form.departmentId).designations
        if (!this.roles.map(r => r.id).includes(this.form.roleId)) {
          this.form.roleId = null
        }
        if (!this.designations.map(r => r.id).includes(this.form.designationId)) {
          this.form.designationId = null
        }
      } else {
        this.roles = []
        this.designations = []
      }
    },
    'form.wards'() {
      this.form.wardIds = this.form.wards.map(w => w.id)
    },
  },

  computed: {
    ...mapState('category', ['departments']),

    phoneNumber: {
      get() {
        return this.form.mobileNumber?.split('+91').pop()
      },
      set(value) {
        this.form.mobileNumber = `+91${value}`
      },
    },

    title() {
      return !this.editing ? 'Create User' : 'Update User'
    },

    passwordConfirmationRules() {
      const rules = []
      if (this.form.password) rules.push(v => !!v || 'Password Confirmation is required')
      rules.push(v => {
        if (!v) return true
        return v === this.form.password || 'Password not match'
      })
      return rules
    },

    passwordRules() {
      const rules = []
      if (!this.editing) rules.push(v => !!v || 'Password is required')
      rules.push(v => {
        if (!v) return true
        return (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(v) ||
          'Password must contain at least 8 characters including one upper case letter, one lower case letter and one number'
        )
      })
      return rules
    },
  },
}
</script>
