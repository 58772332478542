import { mdiDelete, mdiPencil, mdiPlus, mdiRefresh, mdiDownload, mdiMagnify } from '@mdi/js'
const dataTableMixin = destroy => ({
  data() {
    return {
      sortOptions: {},
      icons: {
        mdiDelete,
        mdiPencil,
        mdiPlus,
        mdiRefresh,
        mdiDownload,
        mdiMagnify,
      },
    }
  },
  props: ['tableData', 'form', 'pagination', 'params', 'height', 'search', 'options'],
  computed: {
    headers() {
      return []
    },
  },
  methods: {
    getHeaders() {
      return this.headers
    },
    async handleDelete(id) {
      try {
        await this.$confirm('Are you sure when deleting this row?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await destroy(id)
        this.$message('Deleted Successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
    sort() {
      const { sortBy, sortDesc } = this.sortOptions
      this.$emit('sorted', { sortBy: sortBy[0], sortDesc: sortDesc[0] })
    },
  },
  watch: {
    sortOptions: {
      handler() {
        this.sort()
      },
      deep: true,
    },
  },
})

export default dataTableMixin
