<template>
  <v-data-table
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-text-field
          v-model="params.search"
          style="max-width: 300px"
          label="Search"
          clearable
          hide-details
          dense
          :append-icon="icons.mdiMagnify"
          single-line
          @keyup.enter="$emit('on-search')"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn v-if="tab === 0" icon color="primary" @click="$emit('on-create')">
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="$emit('on-export')">
          <v-icon>{{ icons.mdiDownload }}</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-switch @change="updateActive($event, item)" v-model="item.isActive" dense hide-details class="mt-0"></v-switch>
    </template>
    <template v-slot:item.roleId="{ item }">
      <span>{{ roles?.find(r => r.id === item.roleId)?.text }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn v-if="tab === 0" x-small text fab color="primary" @click="$emit('on-edit', item)">
        <v-icon>{{ icons.mdiPencil }}</v-icon>
      </v-btn>
      <v-btn class="ml-2" text x-small fab color="error" @click="handleDelete(item.id)">
        <v-icon>{{ icons.mdiDelete }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { destroy, active } from '@/api/user'
import dataTableMixin from '@/mixins/crud/data-table'
import { mdiCheck } from '@mdi/js'
import { roles } from '@/constants/roles'

export default {
  mixins: [dataTableMixin(destroy)],
  props: {
    tab: Number,
  },
  methods: {
    async updateActive(value, item) {
      this.$loader(true)
      try {
        await active(item.id, value)
        this.$message('Updated Successfully', 'success')
        this.$emit('updated')
      } catch {
        item.isActive = !value
      } finally {
        this.$loader(false)
      }
    },
  },
  computed: {
    headers() {
      return this.tab === 0
        ? [
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Mobile', value: 'mobileNumber' },
            { text: 'Email', value: 'email' },
            { text: 'Department', value: 'department.name', sortable: false },
            { text: 'Designation', value: 'designation.name', sortable: false },
            { text: 'Role', value: 'roleId', sortable: false },
            { text: 'Active', value: 'isActive' },
            {
              text: 'Actions',
              value: 'actions',
              align: 'center',
              width: '110',
              sortable: false,
            },
          ]
        : [
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Mobile', value: 'mobileNumber' },
            { text: 'Address', value: 'address' },
            { text: 'Active', value: 'isActive' },
            {
              text: 'Actions',
              value: 'actions',
              align: 'center',
              width: '110',
              sortable: false,
            },
          ]
    },
  },
  data() {
    return { roles, icons: { mdiCheck } }
  },
}
</script>
